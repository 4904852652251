import { defaultSysConf } from "@/constant/defaultSysConfig";
import { createSlice } from "@reduxjs/toolkit";
// export const getUserAuth = createAsyncThunk(
//   "getUserAuth",
//   async () => await request("/api/backend/getUserAuth"),
// )
// export const getUserInfo = createAsyncThunk(
//   "getUserInfo",
//   async () => await request("/api/backend/getUserInfo"),
// )
const sysReduce = createSlice({
    name: "sysModel",
    initialState: {
        sysConfig: defaultSysConf,
    },
    reducers: {
        updateSysConfig(state, action) {
            // console.log(action.payload);
            state.sysConfig = action.payload;
        },
    },
    // extraReducers(builder) {
    //   builder.addCase(getUserAuth.fulfilled, (state, action) => {})
    //   builder.addCase(getUserInfo.fulfilled, (state, action) => {})
    // },
});
export const { updateSysConfig } = sysReduce.actions;
export default sysReduce.reducer;
