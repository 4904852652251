import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import "./index.less";
import LogoImg from "@/images/LOGO.png";
import { basename } from "@/constant/router";
const ProHeader = () => {
    var _a;
    const sysModelStore = useSelector((state) => state.sysModel);
    const userModelStore = useSelector((state) => state.userModel);
    const { sysTitle, sysLogo } = sysModelStore.sysConfig;
    return (_jsxs("div", { className: "saberKB-ui-proHeader" /* stylePrefix.ProHeader */, children: [_jsxs("div", { className: `${"saberKB-ui-proHeader" /* stylePrefix.ProHeader */}-title`, onClick: () => {
                    window.open(`${basename}/front/home`, "_self");
                }, children: [_jsx("img", { className: `${"saberKB-ui-proHeader" /* stylePrefix.ProHeader */}-title-logo`, alt: "logo", src: sysLogo ? `/images/${sysLogo}` : LogoImg }), _jsx("div", { className: `${"saberKB-ui-proHeader" /* stylePrefix.ProHeader */}-title-text`, children: sysTitle })] }), _jsxs("div", { className: `${"saberKB-ui-proHeader" /* stylePrefix.ProHeader */}-user`, children: [_jsx("span", { className: `${"saberKB-ui-proHeader" /* stylePrefix.ProHeader */}-user-name`, children: (_a = userModelStore === null || userModelStore === void 0 ? void 0 : userModelStore.userInfo) === null || _a === void 0 ? void 0 : _a.userName }), _jsx("img", { className: `${"saberKB-ui-proHeader" /* stylePrefix.ProHeader */}-user-img`, alt: "logo", src: sysLogo ? `/images/${sysLogo}` : LogoImg })] })] }));
};
export default ProHeader;
