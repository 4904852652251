// import { request } from "@/utils/request";
import { createSlice } from "@reduxjs/toolkit";
const topicListStore = createSlice({
    name: "topicListStore",
    initialState: {
        treeSelected: {},
        treeData: [],
        topicList: { list: [], total: 0 },
        topicSelected: {},
    },
    reducers: {
        updateTreeSelect(state, action) {
            state.treeSelected = action.payload;
        },
        updateTreeData(state, action) {
            state.treeData = action.payload;
        },
        updateTopicList(state, action) {
            state.topicList = {
                total: action.payload.total,
                list: state.topicList.list.concat(action.payload.list),
            };
        },
        updateTopicSelected(state, action) {
            state.topicSelected = action.payload;
        },
        resetTopicList(state) {
            state.topicList = { list: [], total: 0 };
        },
        resetTopicSelected(state) {
            state.topicSelected = {};
        },
    },
    extraReducers(builder) {
        // builder.addCase(getUserAuth.fulfilled, (state, action) => {})
        // builder.addCase(getUserInfo.fulfilled, (state, action) => {})
    },
});
export const { updateTreeSelect, updateTopicList, updateTreeData, updateTopicSelected, resetTopicList, resetTopicSelected } = topicListStore.actions;
export default topicListStore.reducer;
