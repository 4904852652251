import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ConfigProvider, FloatButton, Layout } from "antd";
import { useMemo } from "react";
import ProHeader from "./component/ProHeader";
import ProSider from "./component/ProSider";
import ProFooter from "./component/ProFooter";
import "dayjs/locale/zh-cn";
import { Prefix } from "@/constant/stylePrefix";
import { useLocation } from "react-router-dom";
//App.js
import img2 from "@/images/gh_2433dd8cf946_258.jpg";
import img1 from "@/images/WechatIMG195.jpg";
import zh_CN from "antd/es/locale/zh_CN";
import { findTreeItem } from "@/utils/dataProcess";
import FrontHeader from "./component/FrontHeader";
import { UserAddOutlined, WechatFilled, } from "@ant-design/icons";
const { Sider, Content } = Layout;
const ContentStyle = {
    position: "relative",
    background: "#f5f5f5",
    overflow: "auto",
    height: "calc(100vh - 50px)",
};
const ProLayout = ({ children, primaryColor = "#1147FE", routerData = [], }) => {
    const location = useLocation();
    const isLoginPage = location.pathname.includes("/login");
    const isFront = location.pathname.includes("/front");
    const menuRender = useMemo(() => {
        const _routerItem = findTreeItem(routerData, location.pathname, {
            childrenName: "children",
            keyName: "path",
        });
        return !(typeof (_routerItem === null || _routerItem === void 0 ? void 0 : _routerItem.menuRender) === "boolean" && !(_routerItem === null || _routerItem === void 0 ? void 0 : _routerItem.menuRender));
    }, [routerData]);
    return (_jsx(ConfigProvider, { theme: {
            cssVar: { prefix: Prefix },
            components: {
                Menu: {
                    colorBgContainer: "rgb(169, 184, 239)",
                },
                Notification: {
                    width: 300,
                },
            },
            token: {
                colorPrimary: "rgb(92, 110, 176)",
            },
        }, prefixCls: Prefix, locale: zh_CN, children: _jsx(Layout, { style: { minHeight: "100vh" }, children: isLoginPage ? (children) : menuRender ? (_jsxs(_Fragment, { children: [_jsx(ProHeader, {}), _jsxs(Layout, { children: [_jsx(Sider, { width: 180, theme: "dark", children: _jsx(ProSider, { routerData: routerData }) }), _jsx(Content, { style: ContentStyle, children: children })] })] })) : (_jsxs(_Fragment, { children: [isFront ? _jsx(FrontHeader, {}) : _jsx(ProHeader, {}), isFront ? (_jsxs("div", { style: { marginTop: 50 }, children: [children, _jsx(ProFooter, {})] })) : (_jsx(Content, { style: Object.assign({}, ContentStyle), children: children })), _jsxs(FloatButton.Group, { shape: "circle", style: { right: 8 }, children: [_jsx(FloatButton, { icon: _jsx(UserAddOutlined, {}), tooltip: _jsx("div", { children: _jsx("img", { src: img1, alt: "\u5FAE\u4FE1", style: { height: 120, width: 120 } }) }) }), _jsx(FloatButton, { icon: _jsx(WechatFilled, {}), tooltip: _jsx("div", { children: _jsx("img", { src: img2, alt: "\u5C0F\u7A0B\u5E8F\u7801", style: { height: 120, width: 120 } }) }) }), _jsx(FloatButton.BackTop, { visibilityHeight: 0, target: () => window })] }), !isFront && _jsx(ProFooter, {})] })) }) }));
};
export default ProLayout;
