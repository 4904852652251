import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from "antd";
import "./index.less";
import { getCategoryTreeForFront } from "@/services/home";
import { useNavigate } from "react-router-dom";
import LogoImg from "@/images/LOGO.png";
import { useRequest } from "ahooks";
import { useMemo } from "react";
import { useSelector } from "react-redux";
const themeMode = "light";
const FrontHeader = () => {
    const navigate = useNavigate();
    const { data: cateDataOrigin = [] } = useRequest(getCategoryTreeForFront, {
    // ready: false,
    });
    const sysModelStore = useSelector((state) => state.sysModel);
    const { sysLogo, sysTitle, sysIcon } = sysModelStore.sysConfig;
    const menuData = useMemo(() => {
        const deepProcessCate = (originData = []) => {
            const menuItems = [];
            originData.forEach((item) => {
                const _children = deepProcessCate(item.children);
                menuItems.push({
                    key: item.categoryId,
                    label: item.categoryName,
                    children: !!_children.length && deepProcessCate(item.children),
                });
            });
            return menuItems;
        };
        const _data = [
            {
                label: "首页",
                key: "home",
            },
            ...deepProcessCate(cateDataOrigin.data),
        ];
        return _data;
    }, [cateDataOrigin]);
    const handleMenu = ({ key, keyPath }) => {
        const _ancestor = [...keyPath].reverse();
        _ancestor.pop();
        _ancestor.shift();
        if (key === "home") {
            navigate(`/front/home`);
        }
        else {
            navigate(`/front/list?cateId=${key}`, { state: { changeCate: 1 } });
        }
    };
    return (_jsxs("div", { className: `${"saberKB-ui-frontHeader" /* stylePrefix.FrontHeader */} ${"saberKB-ui-frontHeader" /* stylePrefix.FrontHeader */}-${themeMode}`, children: [_jsxs("div", { className: `${"saberKB-ui-frontHeader" /* stylePrefix.FrontHeader */}-title ${"saberKB-ui-frontHeader" /* stylePrefix.FrontHeader */}-title-${themeMode}`, onClick: () => {
                    navigate(`/front/home`);
                }, children: [_jsx("img", { src: sysLogo ? `/images/${sysLogo}` : LogoImg, alt: "logo" }), _jsx("div", { className: `${"saberKB-ui-frontHeader" /* stylePrefix.FrontHeader */}-title-text`, children: sysTitle })] }), _jsx("div", { className: `${"saberKB-ui-frontHeader" /* stylePrefix.FrontHeader */}-menu`, children: _jsx(Menu, { mode: "horizontal", theme: themeMode, onClick: handleMenu, items: menuData }) })] }));
};
export default FrontHeader;
