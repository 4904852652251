import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, Route } from "react-router-dom";
const routerPathProcess = (path) => {
    const routerPathList = path.split(":")[0].split("");
    routerPathList.pop();
    // const newPath = routerPathList.join("");
    return routerPathList.join("");
};
const importRouterDom = (routerSource) => {
    let domList = {};
    const deepFn = (_routerSource) => {
        _routerSource.forEach((item) => {
            const newPath = routerPathProcess(item.path || "");
            if (item.children) {
                deepFn(item.children);
            }
            if (item.component) {
                domList[newPath] = React.lazy(() => import(`@/pages${item.component}`));
            }
        });
    };
    deepFn(routerSource);
    // console.log(domList);
    return domList;
};
const routerProcess = (routerData, domList) => {
    const newPath = routerPathProcess(routerData.path || "");
    if (routerData.children && routerData.children.length > 0) {
        if (routerData.component) {
            // console.log(routerData)
            return (_jsx(Route, { path: routerData.path, Component: domList[`${newPath}`], children: routerData.children.map((child) => routerProcess(child, domList)) }, routerData.path));
        }
        else {
            return (_jsx(Route, { path: routerData.path, children: routerData.children.map((child) => routerProcess(child, domList)) }, routerData.path));
        }
    }
    else {
        if (routerData.component) {
            // console.log(routerData)
            return (_jsx(Route, { path: routerData.path, Component: domList[`${newPath}`] }, routerData.path));
        }
        else {
            if (routerData.redirect) {
                return (_jsx(Route, { path: routerData.path, element: _jsx(Navigate, { to: routerData.redirect, replace: true }) }));
            }
            return _jsx(Route, { path: routerData.path }, routerData.path);
        }
    }
};
const deepRouterAuthCheck = (routerSource, authCode = []) => {
    if (!(routerSource === null || routerSource === void 0 ? void 0 : routerSource.length)) {
        return [];
    }
    const newRouterSource = routerSource
        .map((item) => {
        if (item.component) {
            // const LazyCompontent = lazy(() => import(`@/pages${item.path}`));
            return Object.assign(Object.assign({}, item), { path: `${item.path}`, key: `${item.path}`, children: item.children
                    ? deepRouterAuthCheck(item.children, authCode)
                    : undefined });
        }
        else {
            return Object.assign(Object.assign({}, item), { path: `${item.path}`, key: `${item.path}`, children: item.children
                    ? deepRouterAuthCheck(item.children, authCode)
                    : undefined });
        }
    })
        .filter((item) => (item.pri ? authCode.includes(item.pri) : true));
    return newRouterSource;
};
export { routerProcess, importRouterDom, deepRouterAuthCheck };
