import { __awaiter } from "tslib";
import { request } from "@/utils/request";
import { REQUEST_PREFIX } from "./const";
const prefix_sys = REQUEST_PREFIX + "/api/systemManage/sysConfig";
export function getSysConf() {
    return __awaiter(this, void 0, void 0, function* () {
        return request(`${prefix_sys}/getSysConf`, {
            method: "get",
        });
    });
}
export function updateConfig(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return request(`${prefix_sys}/updateSysConfig`, {
            method: "post",
            data: params,
        });
    });
}
