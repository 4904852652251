import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
const ProFooter = () => {
    const sysModelStore = useSelector((state) => state.sysModel);
    const { sysFooter } = sysModelStore.sysConfig;
    return (_jsxs("div", { style: {
            position: "relative",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
        }, children: [_jsxs("text", { children: ["Copyrigth @ ", sysFooter] }), _jsx("a", { href: "http://beian.miit.gov.cn/", target: "blank", style: { marginLeft: 12 }, children: _jsx("text", { children: "\u6D59ICP\u5907:2024125808\u53F7" }) })] }));
};
export default ProFooter;
