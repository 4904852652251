import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "@/store";
import { Provider } from "react-redux";
import { basename } from "./constant/router";
import { BrowserRouter } from "react-router-dom";
import { Skeleton } from "antd";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback={<Skeleton></Skeleton>}>
      <BrowserRouter basename={basename}>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);
