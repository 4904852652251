const deepProduceMenu = (router) => {
    if (!(router === null || router === void 0 ? void 0 : router.length)) {
        return [];
    }
    const _list = [];
    router.forEach((item) => {
        var _a;
        if (item.title &&
            !(typeof item.menuRender === "boolean" && !item.menuRender)) {
            if (!(typeof (item === null || item === void 0 ? void 0 : item.menuRender) === "boolean" && !(item === null || item === void 0 ? void 0 : item.menuRender))) {
                _list.push({
                    key: `${item.path}`,
                    label: item.title,
                    children: (item === null || item === void 0 ? void 0 : item.children) && deepProduceMenu(item.children).length
                        ? deepProduceMenu(item.children)
                        : undefined,
                });
            }
        }
        else {
            if (item.children && ((_a = deepProduceMenu(item.children)) === null || _a === void 0 ? void 0 : _a.length)) {
                _list.push(...deepProduceMenu(item.children));
            }
        }
    });
    return _list;
};
const findMenuSelectKey = (menuItems, pathname) => {
    let menuMapPath = {};
    const dfsMenu = (menu) => {
        menu.forEach((item) => {
            if (item.children) {
                dfsMenu(item.children);
            }
            menuMapPath[item.key] = item.key;
        });
    };
    dfsMenu(menuItems);
    let pathnameList = pathname.split("/");
    let key = "";
    while (!menuMapPath[pathnameList.join("/")] && pathnameList.length > 0) {
        pathnameList.length = pathnameList.length - 1;
        key = pathnameList.join("/");
    }
    return key || pathnameList.join("/");
};
const findMenuOpenKeys = (menuData, selectKey) => {
    let _keys = [];
    if (!(menuData === null || menuData === void 0 ? void 0 : menuData.length)) {
        return [];
    }
    menuData.forEach((item) => {
        if (selectKey.includes(item.key)) {
            _keys.push(item.key);
        }
        if (item.children && item.children.length) {
            _keys.push(...findMenuOpenKeys(item.children, selectKey));
        }
    });
    return _keys;
};
export { deepProduceMenu, findMenuSelectKey, findMenuOpenKeys };
