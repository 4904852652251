function findTreeItem(data, key, fieldName = { childrenName: "children", keyName: "id" }) {
    // console.log(data, key, fieldName);
    if (!Array.isArray(data))
        return null;
    if (!data.length)
        return null;
    for (let i = 0; i < data.length; i++) {
        if (data[i][fieldName.keyName] === key) {
            return data[i]; // 找到节点，返回该节点
        }
        if (data[i][fieldName.childrenName] &&
            data[i][fieldName.childrenName].length > 0) {
            const foundNode = findTreeItem(data[i][fieldName.childrenName], key, fieldName);
            if (foundNode) {
                return foundNode;
            }
        }
    }
    return null; // 没有找到节点，返回null
}
function findBroOrFather(parentKey, currentNodeKey, dataOrigin, _fieldName = { childrenName: "children", keyName: "id" }) {
    function traverse(nodes) {
        var _a;
        for (let node of nodes) {
            if (!parentKey) {
                return nodes.filter((item) => item[_fieldName.keyName] != currentNodeKey)[0];
            }
            if (node[_fieldName.keyName] == parentKey) {
                // 如果找到了父节点，返回它的第一个子节点或自身
                if (((_a = node[_fieldName.childrenName]) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                    return node[_fieldName.childrenName].filter((item) => item[_fieldName.keyName] != currentNodeKey)[0];
                }
                else {
                    return node;
                }
            }
            const result = traverse((node === null || node === void 0 ? void 0 : node[_fieldName.childrenName]) || []);
            if (result)
                return result; // 如果在子节点中找到了，直接返回
        }
        return null; // 没有找到父节点
    }
    return traverse(dataOrigin);
}
export { findTreeItem, findBroOrFather };
