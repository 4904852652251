import { configureStore } from "@reduxjs/toolkit";
import userModel from "@/pages/login/store/userReducer";
import sysModel from "./sysReduce";
import cateTreeData from "@/pages/backend/CateManage/store/cateTreeReduce";
import cateTreeShow from "@/pages/backend/TopicManage/store/cateTreeReduce";
import topicListStore from "@/pages/front/list/store/topicListReducer";
const store = configureStore({
    reducer: {
        /**
         * 们需要从上面的创建的空的 store 导入 reducer 函数并将其添加到我们的存储中，
         * 通过在 reducer 参数中定义一个字段，告诉 store 使用这个 slice reducer 函数来处理该状态的所有更新。
         */
        sysModel: sysModel,
        userModel: userModel,
        categoryTree: cateTreeData,
        cateTreeShow: cateTreeShow,
        topicListStore: topicListStore,
    },
    // middleware: [],
    // 启用Redux DevTools，默认true
    // devTools: true,
});
export default store;
