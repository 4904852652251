import { jsx as _jsx } from "react/jsx-runtime";
import "./App.less";
import { Suspense, useEffect, useMemo } from "react";
import ProLayout from "./components/Layout";
import routerData from "../config/router";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { deepRouterAuthCheck, importRouterDom, routerProcess, } from "./utils/router";
import { Skeleton } from "antd";
import { createGlobalStyle } from "./utils/styleProcess";
import { Prefix } from "./constant/stylePrefix";
import { useCounter, useRequest } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import { getSysConf } from "./services/systenConf";
import { updateSysConfig } from "./store/sysReduce";
import { defaultSysConf } from "./constant/defaultSysConfig";
const createFavicon = (url) => {
    const link = document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = `${url}`;
    document.getElementsByTagName("head")[0].appendChild(link);
};
function App() {
    const location = useLocation();
    const [count, { inc }] = useCounter(1);
    const dispatch = useDispatch();
    const sysModelStore = useSelector((state) => state.sysModel);
    const userInfoStore = useSelector((state) => state.userModel);
    const navigator = useNavigate();
    // 监听路由变化
    useEffect(() => {
        var _a;
        const isLogin = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.login;
        if (isLogin) {
            inc();
        }
        if (location.pathname.startsWith("/backend") &&
            !userInfoStore.userInfo.userAccount) {
            navigator("/login", { state: { login: 1 } });
        }
    }, [location]);
    const routerDom = useMemo(() => {
        // console.log("路由发生变化", count);
        const _router = deepRouterAuthCheck(routerData, userInfoStore.userAuthList);
        // console.log(_router);
        return _router;
    }, [userInfoStore.userAuthList, count]);
    useEffect(() => {
        createGlobalStyle(Prefix, "theme-primary", "rgb(92, 110, 176)");
        createGlobalStyle(Prefix, "theme-primary-1", "#0935a4");
        createGlobalStyle(Prefix, "theme-primary-5", "rgb(92, 110, 176,0.2)");
        createGlobalStyle(Prefix, "border", "1px solid rgb(92, 110, 176,0.2)");
    }, []);
    useEffect(() => {
        if (sysModelStore.sysConfig.sysIcon)
            createFavicon(`/images/${sysModelStore.sysConfig.sysIcon}`);
    }, [sysModelStore.sysConfig.sysIcon]);
    const {} = useRequest(getSysConf, {
        manual: location.pathname.includes("/login") || location.pathname.endsWith("/"),
        onSuccess: (res) => {
            // console.log(resdata);
            document.title = res.data.sysTitle || defaultSysConf.sysTitle;
            dispatch(updateSysConfig(res.data));
        },
    });
    const RouterNode = useMemo(() => {
        return (_jsx(Suspense, { fallback: _jsx(Skeleton, {}), children: _jsx(Routes, { children: routerData.map((routerItem) => {
                    // console.log(routerProcess(routerItem, importRouterDom(routerDom)));
                    return routerProcess(routerItem, importRouterDom(routerDom));
                }) }) }));
    }, [routerDom]);
    return (
    // 考虑使用高阶组件优化
    _jsx(ProLayout, { routerData: routerDom, children: RouterNode }, count));
}
export default App;
