import { createSlice } from "@reduxjs/toolkit";
const userModel = createSlice({
    name: "userModel",
    initialState: {
        userInfo: JSON.parse(sessionStorage.getItem("saberUserInfo") || JSON.stringify({})),
        userAuthList: JSON.parse(sessionStorage.getItem("saberUserAuthList") || JSON.stringify([])),
    },
    reducers: {
        updateUser(state, action) {
            state.userInfo = action.payload.userInfo;
            state.userAuthList = action.payload.userAuthList;
        },
    },
    extraReducers(builder) { },
});
export const { updateUser } = userModel.actions;
export default userModel.reducer;
