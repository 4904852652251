import { createSlice } from "@reduxjs/toolkit";
const cateTreeData = createSlice({
    name: "cateTreeShow",
    initialState: {
        treeSelected: {},
        treeData: "",
    },
    reducers: {
        updateTreeSelect(state, action) {
            state.treeSelected = Object.assign({}, action.payload);
        },
        updateTreeData(state, action) {
            state.treeData = action.payload;
        },
    },
    extraReducers(builder) {
        // builder.addCase(getUserAuth.fulfilled, (state, action) => {})
        // builder.addCase(getUserInfo.fulfilled, (state, action) => {})
    },
});
export const { updateTreeSelect, updateTreeData } = cateTreeData.actions;
export default cateTreeData.reducer;
