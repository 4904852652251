import { basename } from "@/constant/router";
import { notification } from "antd";
import axios from "axios";
export const codeMessage = {
    200: "服务器成功返回请求的数据。",
    201: "新建或修改数据成功。",
    202: "一个请求已经进入后台排队（异步任务）。",
    204: "删除数据成功。",
    400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
    401: "用户没有权限（令牌、用户名、密码错误）。",
    403: "用户得到授权，但是访问是被禁止的。",
    404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
    406: "请求的格式不可得。",
    410: "请求的资源被永久删除，且不会再得到的。",
    422: "当创建一个对象时，发生一个验证错误。",
    500: "服务器发生错误，请检查服务器。",
    502: "网关错误。",
    503: "服务不可用，服务器暂时过载或维护。",
    504: "网关超时。",
};
export var ERequestMethod;
(function (ERequestMethod) {
    ERequestMethod["POST"] = "post";
    ERequestMethod["GET"] = "get";
    ERequestMethod["DELETE"] = "delete";
    ERequestMethod["PUT"] = "put";
    ERequestMethod["HEAD"] = "head";
    ERequestMethod["OPTIONS"] = "options";
    ERequestMethod["TRACE"] = "trace";
    ERequestMethod["CONNECT"] = "connect";
})(ERequestMethod || (ERequestMethod = {}));
// 请求超时时间
axios.defaults.timeout = 10000;
//默认请求地址
// axios.defaults.baseURL = "http://121.40.65.189:8080";
// post请求头
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器
axios.interceptors.request.use((config) => {
    //axios 会根据参数的数据类型 自动填充Content-type ，但是formDate类型的参数不会赋值，这一点会由浏览器进行识别和填充
    let { headers } = config;
    headers = Object.assign({}, headers);
    // if (
    //   typeof config.data === "object" &&
    //   !(config.headers["Content-Type"] || headers["Content-Type"])
    // ) {
    //   config.headers["Content-Type"] = "application/json";
    //   if (config.method === ERequestMethod.POST) {
    //     config.data = JSON.stringify(config.data);
    //   }
    // }
    // console.log(config);
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    return config;
}, (error) => {
    return Promise.reject(error);
});
// 响应拦截器
axios.interceptors.response.use((response) => {
    if (response.status === 200) {
        // console.log(response);
        if (response.data.code !== 200) {
            notification.error({
                message: response.data.code,
                description: response.data.msg,
            });
            return Promise.reject(response.data.msg);
        }
        // console.log();
        return Promise.resolve(response.data);
    }
    else {
        return Promise.reject(response);
    }
}, 
// 服务器状态码不是200的情况
(error) => {
    if (error.response.status) {
        notification.error({
            message: error.response.status,
            description: error.response.status === 401
                ? "登录已过期，请重新登录！正在为您跳转登录页面..."
                : codeMessage[error.response.status],
        });
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
                // console.log(error)
                setTimeout(() => {
                    window.open(`${basename}/login`, "_self");
                }, 1000);
                break;
            // 403 token过期
            // 登录过期对用户进行提示
            // 跳转登录页面
            case 403:
                // 清除token
                break;
            // 404请求不存在
            case 404:
                break;
            // 其他错误，直接抛出错误提示
            default:
        }
        return Promise.reject(error.response);
    }
});
export function request(url, config) {
    return new Promise((resolve, reject) => {
        axios(Object.assign({ url }, config))
            .then((res) => {
            resolve(res);
        })
            .catch((error) => {
            reject(error);
        });
    });
}
