const router = [
    { path: "/", redirect: "/front/home" },
    {
        path: "/login",
        component: "/login",
        menuRender: false,
    },
    {
        path: "/front",
        children: [
            { path: "/front", redirect: "/front/home" },
            {
                menuRender: false,
                path: "/front/home",
                component: "/front/home",
            },
            {
                menuRender: false,
                path: "/front/list",
                component: "/front/list",
            },
        ],
    },
    {
        path: "/backend",
        children: [
            { path: "/backend", redirect: "/backend/KBManage/cateManage" },
            {
                title: "知识库管理",
                path: "/backend/KBManage",
                children: [
                    {
                        title: "类目管理",
                        path: "/backend/KBManage/cateManage",
                        component: "/backend/CateManage",
                        pri: "kb-topic-manager" /* UserPrivilegeAuthEnum.kbTopicManager */,
                    },
                    {
                        title: "知识管理",
                        path: "/backend/KBManage/topicManage",
                        component: "/backend/TopicManage",
                        pri: "kb-topic-manager" /* UserPrivilegeAuthEnum.kbTopicManager */,
                    },
                    {
                        // title: "知识编辑",
                        path: "/backend/KBManage/topicManage/topicDetail",
                        component: "/backend/TopicManage/topicDetail",
                        pri: "kb-topic-manager" /* UserPrivilegeAuthEnum.kbTopicManager */,
                    },
                ],
            },
            {
                path: "/backend/systemManage",
                title: "系统管理",
                pri: "kb-system-manager" /* UserPrivilegeAuthEnum.kbSystemManager */,
                children: [
                    {
                        title: "账户管理",
                        path: "/backend/systemManage/accountManage",
                        component: "/backend/systemManage/accountManage",
                    },
                    {
                        title: "角色管理",
                        path: "/backend/systemManage/characterManage",
                        component: "/backend/systemManage/characterManage",
                    },
                    {
                        title: "权限管理",
                        path: "/backend/systemManage/authorityManage",
                        component: "/backend/systemManage/authorityManage",
                    },
                    {
                        title: "系统配置",
                        path: "/backend/systemManage/systemConfig",
                        component: "/backend/systemManage/systemConfig",
                    },
                ],
            },
        ],
    },
];
export default router;
