import { createSlice } from "@reduxjs/toolkit";
// export const getCategoryData = createAsyncThunk("getCategoryData", async () => {
//   const response: any = await getCategoryTree();
//   return response.data;
// });
const cateTreeData = createSlice({
    name: "cateTree",
    initialState: {
        treeSelected: {},
        treeData: "",
        refreshTag: 0,
    },
    reducers: {
        updateTreeSelect(state, action) {
            state.treeSelected = Object.assign({}, action.payload);
        },
        updateTreeData(state, action) {
            state.treeData = action.payload;
        },
        refreshTreeTag(state) {
            state.refreshTag = state.refreshTag + 1;
        },
    },
    // extraReducers(builder) {
    //   builder
    //     .addCase(getCategoryData.pending, (state, action) => {
    //       state.treeLoading = true;
    //     })
    //     .addCase(getCategoryData.fulfilled, (state, action) => {
    //       console.log(action.payload);
    //       state.treeLoading = false;
    //     });
    //   // builder.addCase(getUserInfo.fulfilled, (state, action) => {})
    // },
});
export const { updateTreeSelect, updateTreeData, refreshTreeTag } = cateTreeData.actions;
export default cateTreeData.reducer;
