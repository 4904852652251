import { jsx as _jsx } from "react/jsx-runtime";
import { Menu } from "antd";
import { useMemo, useState } from "react";
import { deepProduceMenu, findMenuOpenKeys, findMenuSelectKey } from "./const";
import { useLocation, useNavigate } from "react-router-dom";
const ProSider = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState([]);
    const [selectMenu, setSelectMenu] = useState("");
    // console.log(location);
    const menuItems = useMemo(() => deepProduceMenu(props.routerData), [props.routerData]);
    const menuState = useMemo(() => {
        const _selectKey = findMenuSelectKey(menuItems, location.pathname);
        const _openKeys = findMenuOpenKeys(menuItems, _selectKey);
        setSelectMenu(_selectKey);
        setOpenKeys(_openKeys);
        return {
            openKeys: _openKeys,
        };
    }, [menuItems, location]);
    const onMenuSelect = (keyPath) => {
        if (location.pathname === keyPath[0])
            return;
        if (keyPath[0] === selectMenu) {
            navigate(keyPath[0]);
        }
    };
    const onMenuClick = (keyPath) => {
        setSelectMenu(keyPath[0]);
        navigate(keyPath[0]);
    };
    return (_jsx("div", { children: _jsx(Menu, { theme: "dark", openKeys: openKeys, onOpenChange: (event) => {
                setOpenKeys(event);
            }, 
            // defaultOpenKeys={menuState.openKeys}
            selectedKeys: [selectMenu], mode: "inline", items: menuItems, onClick: ({ keyPath }) => {
                onMenuClick(keyPath);
                // console.log(keyPath);
            }, onSelect: ({ keyPath }) => {
                // console.log(keyPath);
                onMenuSelect(keyPath);
            } }) }));
};
export default ProSider;
