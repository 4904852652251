import { __awaiter } from "tslib";
import { request } from "@/utils/request";
import { REQUEST_PREFIX } from "./const";
const prefix_cate = REQUEST_PREFIX + "/api/cateManage/category";
/**
 * 获取分类目录（一级）
 *
 * @export
 * @returns
 */
export function getCateFirstLevel() {
    return __awaiter(this, void 0, void 0, function* () {
        return request(`${prefix_cate}/getCategoryFirstLevel`, {});
    });
}
/**
 *
 * @returns 返回当前登录用户的分类树数据结构
 */
export function getCategoryTreeForFront(categoryId) {
    return __awaiter(this, void 0, void 0, function* () {
        return request(`${prefix_cate}/getCategoryTreeForHeader?categoryId=${categoryId || ""}`, {});
    });
}
/**
 *
 * @returns 根据id 返回最后一级节点数据，
 */
export function getCategoryLastById(categoryId) {
    return __awaiter(this, void 0, void 0, function* () {
        return request(`${prefix_cate}/getCategoryLastById?categoryId=${categoryId || ""}`, {});
    });
}
